<template>
	<div>
		<v-layout class="p-4 border-bottom-light-grey min-height-57px">
			<v-flex md9 class="font-level-3-bold my-auto">
				<span class="detail-svg-icon mr-2">
					<!--begin::Svg Icon-->
					<inline-svg :src="$assetURL('media/custom-svg/reservation-color.svg')" />
					<!--end::Svg Icon-->
				</span>
				{{ getTitle() }}
			</v-flex>
			<v-flex v-if="status == 'checked-out'" md3 class="text-right my-auto">
				<SelectInput
					v-if="false"
					hide-details
					custom-class="mt-0 pt-0"
					:items="statusList"
					:disabled="pageLoading"
					:loading="pageLoading"
					v-on:change="getReservations()"
					placeholder="Status"
					v-model="status_filter"
				></SelectInput>
			</v-flex>
		</v-layout>
		<div class="overflow-y" style="max-height: calc(100vh - 306px)">
			<table width="100%" class="detail-table table-head-sticky">
				<thead>
					<tr>
						<th width="15%" class="p-2 light-blue-bg">Staff</th>
						<th width="15%" class="p-2 light-blue-bg">Customer</th>
						<th width="15%" class="p-2 light-blue-bg">From</th>
						<th width="15%" class="p-2 light-blue-bg">To</th>
						<th width="15%" class="p-2 light-blue-bg" v-if="status == 'checked-out'">Issued PDF</th>
						<th v-if="false" width="20%" class="p-2 light-blue-bg">Location</th>
						<th width="15%" class="p-2 light-blue-bg">Status</th>
						<th width="30%" class="p-2 light-blue-bg" colspan="2">Comments</th>
					</tr>
				</thead>
				<tbody v-if="reservations.length">
					<tr v-for="(row, index) in reservations" :key="index">
						<td width="15%" class="p-2 border-top-light-grey">
							<ShowValue :object="row" object-key="member_display_name" label="member"></ShowValue>
						</td>
						<td width="15%" class="p-2 border-top-light-grey">
							<ShowValue :object="row" object-key="customer_display_name" label="customer"></ShowValue>
						</td>
						<td width="15%" class="p-2 border-top-light-grey">
							<ShowValue :object="row" object-key="reserve_from_formatted" label="from"></ShowValue>
						</td>
						<td width="15%" class="p-2 border-top-light-grey">
							<ShowValue :object="row" object-key="reserve_to_formatted" label="to"></ShowValue>
						</td>
						<td width="15%" class="p-2 border-top-light-grey" v-if="status == 'checked-out'">
							<v-icon
								v-if="row.signed_pdf"
								v-on:click.stop.prevent="downloadFile(row)"
								class="red--text"
								large
								>mdi-file-pdf-box</v-icon
							>
							<span v-else class="text--secondary">-</span>
						</td>
						<td v-if="false" width="20%" class="p-2 border-top-light-grey">
							<ShowValue :object="row" object-key="location_to_name" label="location"></ShowValue>
						</td>
						<td width="15%" class="p-2 border-top-light-grey">
							<v-chip :color="`${row.statusColor} darken-1`" text-color="white" class="mr-1">{{
								row.status_text
							}}</v-chip>

							<template v-if="row.is_rent">
								<v-Chip color="blue" class="white--text"> Retal</v-Chip>
							</template>
						</td>
						<td class="p-2 border-top-light-grey d-grid">
							<ShowValue :object="row" object-key="description" truncate label="comments"></ShowValue>
						</td>
						<td width="10%" class="p-2 border-top-light-grey" align="right">
							<template v-if="row.status == 1">
								<v-tooltip left content-class="custom-left-tooltip">
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											v-on:click="doAction(row, 'checkout')"
											small
											icon
											depressed
											color="blue darken-4"
											class="mr-2"
											v-bind="attrs"
											v-on="on"
											><v-icon small>mdi-share-outline</v-icon></v-btn
										>
									</template>
									<span> Issue </span>
								</v-tooltip>
								<v-tooltip left content-class="custom-left-tooltip">
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											v-on:click="doAction(row, 'cancel')"
											small
											icon
											depressed
											color="red lighten-1"
											v-bind="attrs"
											v-on="on"
											><v-icon small>mdi-close</v-icon></v-btn
										>
									</template>
									<span> Cancel Reservation </span>
								</v-tooltip>
							</template>
						</td>
					</tr>
				</tbody>
				<tfoot v-else>
					<tr>
						<td colspan="7">
							<p class="m-0 row-not-found text-center py-3">
								<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
								Uhh... There <span class="text-lowercase">are no {{ getTitle() }} at the moment.</span>
							</p>
						</td>
					</tr>
				</tfoot>
			</table>
		</div>
		<Dialog :dialog="checkoutDialog" :dialog-width="dialogWidth">
			<template v-slot:title>Approve Reservation</template>
			<template v-slot:body>
				<p class="m-0 font-level-3">
					{{ typeText }} will be checked out immediately. For future reservations, check out
					<span class="text-lowercase">{{ typeText }}</span> at the scheduled date.
				</p>
			</template>
			<template v-slot:action>
				<v-btn
					:disabled="approveLoading"
					class="mr-2"
					v-on:click="checkoutDialog = false"
					depressed
					tile
					>Cancel</v-btn
				>
				<v-btn
					:disabled="approveLoading"
					:loading="approveLoading"
					color="blue darken-4 text-white"
					depressed
					tile
					v-on:click="approveReservation()"
					>Confirm</v-btn
				>
			</template>
		</Dialog>
		<Dialog :dialog="cancelDialog" :dialog-width="dialogWidth">
			<template v-slot:title>Confirm Cancel Reservation</template>
			<template v-slot:body>
				<p class="m-0 font-level-3">Are you sure you want to cancel the Reservation?</p>
			</template>
			<template v-slot:action>
				<v-btn :disabled="cancelLoading" class="mr-2" v-on:click="cancelDialog = false" depressed tile
					>Cancel</v-btn
				>
				<v-btn
					:disabled="cancelLoading"
					:loading="cancelLoading"
					color="blue darken-4 text-white"
					depressed
					tile
					v-on:click="cancelReservation()"
					>Confirm</v-btn
				>
			</template>
		</Dialog>
	</div>
</template>
<script>
import { toSafeInteger } from "lodash";
import ApiService from "@/core/services/api.service";
import { EventBus } from "@/core/event-bus/event.bus";
import ShowValue from "@/view/components/ShowValue";
import Dialog from "@/view/components/Dialog";
import SelectInput from "@/view/components/SelectInput";

export default {
	props: {
		type: {
			type: String,
			default: null,
		},
		typeText: {
			type: String,
			default: null,
		},
		typeUuid: {
			type: String,
			default: null,
		},
		status: {
			type: String,
			default: "all",
		},
	},
	data() {
		return {
			statusList: [
				{
					text: "Issued",
					value: "checked-out",
				},
				{
					text: "Overdue",
					value: "overdue",
				},
			],
			status_filter: "checked-out",
			reservations: [],
			reservation: {},
			checkoutDialog: false,
			cancelDialog: false,
			pageLoading: true,
			approveLoading: false,
			cancelLoading: false,
		};
	},
	methods: {
		downloadFile(row) {
			window.open(row.signed_pdf, "_blank");
		},
		getTitle() {
			if (this.status == "checked-out") {
				return "Issued";
			}
			return "Reservation";
		},
		doAction(row, param) {
			this.reservation = row;
			switch (param) {
				case "checkout":
					this.checkoutDialog = true;
					break;
				case "cancel":
					this.cancelDialog = true;
					break;
			}
		},
		getReservations() {
			let status = this.status;
			if (status == "checked-out" && this.status_filter) {
				status = this.status_filter;
			}
			ApiService.query(`${this.type}/${this.typeUuid}/reservation`, { status })
				.then(({ data }) => {
					this.reservations = data;
				})
				.catch((error) => {
					this.$emit("error", error);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		approveReservation() {
			this.approveLoading = true;
			ApiService.patch(`${this.type}/${this.typeUuid}/reservation/${this.reservation.uuid}/approve`)
				.then(() => {
					this.getReservations();
					this.checkoutDialog = false;
					EventBus.$emit("reload:asset", true);
					EventBus.$emit("reload:asset-stock", true);
				})
				.catch((error) => {
					this.$emit("error", error);
				})
				.finally(() => {
					this.approveLoading = false;
				});
		},
		cancelReservation() {
			this.cancelLoading = true;
			ApiService.patch(`${this.type}/${this.typeUuid}/reservation/${this.reservation.uuid}/cancel`)
				.then(() => {
					this.getReservations();
					this.cancelDialog = false;
					EventBus.$emit("reload:asset", true);
					EventBus.$emit("reload:asset-stock", true);
				})
				.catch((error) => {
					this.$emit("error", error);
				})
				.finally(() => {
					this.cancelLoading = false;
				});
		},
	},
	components: {
		Dialog,
		ShowValue,
		SelectInput,
	},
	mounted() {
		this.getReservations();

		EventBus.$on("reload:asset-reservation", () => {
			this.getReservations();
		});
	},
	beforeDestroy() {
		EventBus.$off("reload:asset-reservation");
	},
	computed: {
		dialogWidth() {
			return toSafeInteger((document.body.clientWidth / 100) * 50);
		},
	},
};
</script>
